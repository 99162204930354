const appColors = {
  primary: '#e88330',
  secondary: '#397d87',
  paragraphText: '#7d7d7d',
  componentBg: 'white',
  checkoutSectionBorder: '#e2e5e5',
  border: '#F26522',
  /*border: '#d1d1d1',*/
  buttonText: 'white',
};

export default appColors;
