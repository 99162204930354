export const LOGOUT_LOGIN = "LOGOUT_LOGIN";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SUBMIT_PASSWORD = "SUBMIT_PASSWORD";
export const SUBMIT_SOCIAL = "SUBMIT_SOCIAL";

export const START_APPLE_SIGNUP = "START_APPLE_SIGNUP";
export const CHANGE_NEW_EMAIL = "CHANGE_NEW_EMAIL";
export const CHANGE_NEW_PASSWORD = "CHANGE_NEW_PASSWORD";
export const CHANGE_NEW_PASSWORD_CONFIRM = "CHANGE_NEW_PASSWORD_CONFIRM";
export const CHANGE_NEW_FIRST_NAME = "CHANGE_NEW_FIRST_NAME";
export const CHANGE_NEW_LAST_NAME = "CHANGE_NEW_LAST_NAME";
export const CHANGE_NEW_PHONE = "CHANGE_NEW_PHONE";
export const CHANGE_NEW_STREET = "CHANGE_NEW_STREET";
export const CHANGE_NEW_UNIT = "CHANGE_NEW_UNIT";
export const CHANGE_NEW_CITY = "CHANGE_NEW_CITY";
export const CHANGE_NEW_STATE = "CHANGE_NEW_STATE";
export const CHANGE_NEW_ZIP = "CHANGE_NEW_ZIP";
export const SUBMIT_SIGNUP = "SUBMIT_PASSWORD_SIGNUP";
export const TOGGLE_LOGIN_POPUP = "SHOW_LOGIN_POPUP";
export const TOGGLE_SIGNUP_POPUP = "SHOW_SIGNUP_POPUP";